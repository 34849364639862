var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"ClientesForm",scopedSlots:_vm._u([{key:"default",fn:function(data){return [_c('b-form',{attrs:{"id":"ClientesForm"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"required",attrs:{"label":"Nombres"}},[_c('b-form-input',{attrs:{"id":"nombre","name":"nombre","type":"text","state":_vm.errorState(errors)},model:{value:(_vm.form.nombre),callback:function ($$v) {_vm.$set(_vm.form, "nombre", $$v)},expression:"form.nombre"}})],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"required",attrs:{"label":"Apellidos"}},[_c('b-form-input',{attrs:{"id":"apellido","name":"apellido","type":"text","state":_vm.errorState(errors)},model:{value:(_vm.form.apellido),callback:function ($$v) {_vm.$set(_vm.form, "apellido", $$v)},expression:"form.apellido"}})],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Tipo de cliente"}},[_c('v-select',{attrs:{"input-id":"tipo_cliente","label":"nombre","options":_vm.tipo_cliente,"reduce":function (type) { return type.id; },"clearable":false},model:{value:(_vm.form.tipo_cliente),callback:function ($$v) {_vm.$set(_vm.form, "tipo_cliente", $$v)},expression:"form.tipo_cliente"}})],1)],1)],1),_c('b-row'),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Tipo de documento"}},[_c('v-select',{attrs:{"input-id":"tipo_documento","label":"nombre","options":_vm.storeConfig.document_type,"reduce":function (type) { return type.id; },"clearable":false},model:{value:(_vm.form.identification_type),callback:function ($$v) {_vm.$set(_vm.form, "identification_type", $$v)},expression:"form.identification_type"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Numero de identificación"}},[_c('b-form-input',{attrs:{"id":"identification_number","type":"number"},model:{value:(_vm.form.identification_number),callback:function ($$v) {_vm.$set(_vm.form, "identification_number", $$v)},expression:"form.identification_number"}})],1)],1)],1),_c('b-row',{staticStyle:{"position":"relative","z-index":"2"}},[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"required",attrs:{"label":"Celular"}},[_c('vue-phone-number-input',{ref:"inputCelular",attrs:{"error":errors.length > 0,"show-code-on-list":"","default-country-code":"CO","preferred-countries":['CO'],"translations":{
                countrySelectorLabel: 'Código país',
                countrySelectorError: 'Elije un código',
                phoneNumberLabel: 'Celular',
                example: 'Ejemplo :',
              }},on:{"update":function (event) {
                  _vm.form.main_cell_phone = event.nationalNumber;
                  _vm.wame.number = event.e164;
                  _vm.exampleLabel(event)
                if(_vm.wame.state)
                  { _vm.phone.whatsapp = event.e164; }
              }},model:{value:(_vm.phone.main_cell_phone),callback:function ($$v) {_vm.$set(_vm.phone, "main_cell_phone", $$v)},expression:"phone.main_cell_phone"}})],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{staticClass:"required",attrs:{"label":"WhatsApp"}},[_c('vue-phone-number-input',{ref:"whatsapp",attrs:{"error":errors.length > 0,"show-code-on-list":"","default-country-code":"CO","preferred-countries":['CO'],"translations":{
                countrySelectorLabel: 'Código país',
                countrySelectorError: 'Elije un código',
                phoneNumberLabel: 'WhatsApp',
                example: 'Ejemplo :',
              },"disabled":_vm.wame.state},on:{"update":function($event){_vm.form.whatsapp = $event.e164}},model:{value:(_vm.phone.whatsapp),callback:function ($$v) {_vm.$set(_vm.phone, "whatsapp", $$v)},expression:"phone.whatsapp"}})],1)]}}],null,true)}),_c('b-form-checkbox',{staticClass:"mb-0 mt-1",attrs:{"id":"wame","name":"wame"},model:{value:(_vm.wame.state),callback:function ($$v) {_vm.$set(_vm.wame, "state", $$v)},expression:"wame.state"}},[_vm._v(" Usar el mismo numero de Celular ")])],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{staticClass:"required",attrs:{"label":"Correo electrónico"}},[_c('b-form-input',{attrs:{"id":"main_mail","type":"email","state":_vm.errorState(errors)},model:{value:(_vm.form.main_mail),callback:function ($$v) {_vm.$set(_vm.form, "main_mail", $$v)},expression:"form.main_mail"}})],1)]}}],null,true)})],1)],1),_c('b-row',{attrs:{"align-h":"center"}},[_c('b-col',{attrs:{"cols":"auto"}},[_c('b-form-group',{attrs:{"label":"Genero"}},[_c('b-form-radio-group',{attrs:{"name":"genero"},model:{value:(_vm.form.genero),callback:function ($$v) {_vm.$set(_vm.form, "genero", $$v)},expression:"form.genero"}},_vm._l((_vm.storeConfig.gender),function(item){return _c('b-form-radio',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),(_vm.tipo != 'add')?_c('b-col',{attrs:{"cols":"auto"}},[_c('b-form-group',{attrs:{"label":"Calificación del cliente"}},[_c('b-form-rating',{staticClass:"p-0",staticStyle:{"height":"auto"},attrs:{"variant":"warning","no-border":"","inline":"","size":"lg"},model:{value:(_vm.form.clasificacion),callback:function ($$v) {_vm.$set(_vm.form, "clasificacion", $$v)},expression:"form.clasificacion"}})],1)],1):_vm._e()],1),(_vm.tipo != 'add')?_c('b-row',_vm._l((_vm.ubicaciones),function(value,prop){return _c('b-col',{key:prop,attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":value.props.label}},[_c('v-select',{attrs:{"input-id":prop,"options":prop == 'pais' ? _vm.storeConfig.country : _vm.ubicaciones[prop].options,"disabled":prop == 'pais' ? _vm.storeConfig.country.length == 0 : _vm.ubicaciones[prop].options.length == 0,"label":"name","reduce":function (type) { return type.id; },"clearable":false},on:{"input":function () {
              value.props.api ?
              _vm.resetUbicacion(prop):
              null;
            }},model:{value:(_vm.ubicaciones[prop].value),callback:function ($$v) {_vm.$set(_vm.ubicaciones[prop], "value", $$v)},expression:"ubicaciones[prop].value"}})],1)],1)}),1):_vm._e(),(_vm.tipo != 'add')?_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Observaciones"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-center",attrs:{"id":"quill-toolbar"}},[_c('button',{staticClass:"ql-bold"}),_c('button',{staticClass:"ql-italic"}),_c('button',{staticClass:"ql-underline"})]),_c('quill-editor',{attrs:{"options":_vm.quillOption,"id":"quill-content"},model:{value:(_vm.form.observaciones),callback:function ($$v) {_vm.$set(_vm.form, "observaciones", $$v)},expression:"form.observaciones"}})],1)],1)],1):_vm._e()],1),(data.failed)?_c('div',{staticClass:"list-error"},[_c('div',{staticClass:"alert alert-danger p-1 mb-0 flex-grow-1 text-center",style:({ fontSize: '14px' })},[_c('p',[_vm._v("Uno o más campos obligatorios se encuentran vacios, Valida e intenta nuevamente. ")])])]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }